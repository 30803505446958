import "../../css/dropzone.css";

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "../../utils/formatters";

export function DropZone({ onDrop }) {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const maxSize = 1024 * 1024 * 50;
  const maxFiles = 25;

  const { fileRejections, isDragActive, getRootProps, getInputProps } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFiles(acceptedFiles);
      },
      maxFiles,
      maxSize,
    });

  async function handleUpload() {
    if (files.length < 1) return;
    setIsUploading(true);
    await onDrop(files, setFiles);
    setIsUploading(false);
  }

  function truncateText(text, length = 20) {
    if (text.length <= length) return text;
    text = text.split(".");
    var ext = text[text.length - 1];
    return `${text[0].substr(0, length)}....${ext}`;
  }

  return (
    <div className="container pb-m">
      <div className="flex items-start flex-column mt-l">
        <p className="font-xs">
          <span className="clr-danger mr-s">*</span>Max size for each file must
          be less than {formatBytes(maxSize, 0)}
        </p>
        <p className="font-xs">
          <span className="clr-danger mr-s">*</span>Max file count must be less
          than {maxFiles}
        </p>
      </div>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="drop-box flex-center">
          {isDragActive ? (
            <p>Drop...</p>
          ) : (
            <p>
              Drag 'n' drop some files here, or{" "}
              <span className="clr-primary">click</span> to select files
            </p>
          )}
        </div>
      </div>
      {fileRejections && (
        <div className="flex items-start mt-s mb-m">
          <p className="clr-danger">
            {fileRejections[0]?.errors[0]?.code === "file-too-large"
              ? `File is larger than ${formatBytes(maxSize, 0)}`
              : fileRejections[0]?.errors[0]?.message}
          </p>
        </div>
      )}

      <div className="flex-center flex-column mt-l mb-m">
        {isUploading && <span class="loader"></span>}
        {/* <p className="clr-success">Files uploaded successfully...</p> */}
      </div>

      {files?.length > 0 && (
        <div className="flex items-start flex-column mt-l">
          <div
            className="flex space-between align-center"
            style={{ width: "100%" }}
          >
            <h2 className="font-m font-600">
              Files <span className="font-s">{`[${files?.length}]`}</span>
            </h2>
            <button
              onClick={handleUpload}
              disabled={isUploading}
              className={`btn btn-${
                isUploading ? "secondary not-allowed" : "primary"
              }`}
            >
              Upload
            </button>
          </div>
          <ul
            className="flex flex-column items-start mt-s files-box"
            style={{ gap: ".5rem" }}
          >
            {files?.map((file, i) => (
              <li
                key={file.name}
                className="flex align-center"
                style={{ gap: ".75rem" }}
              >
                <i
                  onClick={() => {
                    setFiles((prev) =>
                      [...prev].filter((tf) => tf.name !== file.name)
                    );
                  }}
                  className="fa-solid fa-multiply flex-center remove-icon"
                />
                <span>
                  {i + 1}. {truncateText(file.name)}
                </span>
                <span>{formatBytes(file.size)}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
