import React, { useContext } from "react";
import axios from "axios";

// Context
import TextContext from "../../context/TextContext";
import { DropZone } from "./DropZone";

export default function Upload({ closeUpload }) {
  const { host, key } = useContext(TextContext);

  const uploadFile = async (files, setFiles) => {
    const formdata = new FormData();

    for (let file of files) {
      formdata.append("files", file);
      formdata.append("name", "files");
    }

    try {
      const res = await axios.post(`${host}upload`, formdata, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          room: sessionStorage.getItem("room"),
          key,
        },
      });
      if (res.data.status === 200) {
        setFiles([]);
        setTimeout(() => {
          closeUpload(false);
        }, 1000);
      }
    } catch (error) {
      console.log("Error in file uploading, please try again!");
    }
  };

  return (
    <div className="upload-box p-l br-s text-center">
      <div
        className="close-room"
        title="Close"
        onClick={() => {
          closeUpload(false);
          document.title = "Shre.IN - Share Text and Files";
        }}
      >
        <i className="fa-solid fa-multiply font-m"></i>
      </div>
      <h1 className="clr-primary mt-s mb-m font-m font-700">Upload Files</h1>
      <div id="upload-form">
        <DropZone
          onUpload={() => console.log("Uploaded...")}
          onDrop={uploadFile}
        />
      </div>
    </div>
  );
}
